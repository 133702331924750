const React = require('react')
const {santaTypesDefinitions} = require('santa-components')

const compType = 'GHOST_REF_COMP'

/**
 * @class components.GhostRefComp
 */
class GhostRefComp extends React.Component {
    render() {
        const {id, isCollapsed} = this.props
        return (
            <div id={id} data-collapsed={isCollapsed}>
            </div>
        )
    }
}

GhostRefComp.compType = compType
GhostRefComp.propTypes = {
    id: santaTypesDefinitions.Component.id,
    isCollapsed: santaTypesDefinitions.Component.isCollapsed
}
module.exports = GhostRefComp
